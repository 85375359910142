import styled from 'styled-components';

import { button } from '@/styles/shared';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 15px;
  height: 80vh;

  img {
    max-width: 100vw;
    height: 250px;
    width: auto;
  }

  path {
    border: 1px solid red;
    height: 300px;
  }

  a {
    ${button};
    margin-top: 20px;
    outline-color: ${({ theme }) => theme.palette.primary};

    @media ${({ theme }) => theme.media.tablet} {
      margin-top: 50px;
    }
  }
`;

export const Subheader = styled.p`
  text-align: center;
  width: 100%;
  font-size: 16px;
  margin: 5px 0;

  @media ${({ theme }) => theme.media.tablet} {
    font-size: 20px;
  }
`;

export const Header = styled.h1`
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0;

  @media ${({ theme }) => theme.media.tablet} {
    font-size: 44px;
  }
`;
