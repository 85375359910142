import { getAllCategories } from '@lib/sanity';
import { mapLayout } from '@mappers/layout';
import ErrorHeader from '@molecules/ErrorHeader';
import Layout from '@molecules/Layout';
import type { LayoutProps } from '@molecules/Layout/Layout.types';
import { getClient } from '@sanity/lib/getClient';
import type { GetStaticProps } from 'next';
import type { ReactElement } from 'react';

interface NotFoundPageProps {
  layoutData: Omit<LayoutProps, 'children'>;
}

const NotFoundPage = ({ layoutData }: NotFoundPageProps): ReactElement => (
  <Layout {...layoutData}>
    <ErrorHeader />
  </Layout>
);

export const getStaticProps: GetStaticProps<NotFoundPageProps> = async ({
  preview = false,
}) => {
  const previewToken = preview ? process.env.SANITY_READ_TOKEN : ``;
  const client = getClient(previewToken);

  const tags = await getAllCategories(client);
  const layoutData = mapLayout(tags);

  return {
    props: {
      layoutData,
    },
  };
};

export default NotFoundPage;
