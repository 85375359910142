import Image from '@atoms/Image';
import Link from 'next/link';
import type { ReactElement } from 'react';

import { Header, Subheader, Wrapper } from './ErrorHeader.styled';

export const ErrorHeader = (): ReactElement => (
  <Wrapper>
    <Header>Houston, mamy błąd</Header>
    <Image src="/static/images/404.svg" alt="404" />
    <Subheader>Wygląda na to, że ta strona nie istnieje. 😬</Subheader>
    <Subheader>
      👇 Kliknij poniżej żeby bezpiecznie wrócić na stronę główną.
    </Subheader>
    <Link href="/">🚀 Powrót do domu</Link>
  </Wrapper>
);
